<template>
  <WhatWeDo
    :background-image="this.backgroundImage"
    :background-image-details="'Image Description'"
  >
    <h2>iPad Interactive Voting System</h2>
      <div class="scrollable-content">
    <p>
      This business tool enables a company to promote scientific data and key
      messaging that can be be passed onto delegates at exhibitions, events,
      business training, work and leisure meetings.
    </p>
    <p>
      Our custom built software and hardware system runs via a single MacBook
      with six iPads via our own high speed secure router. This can also be
      operated offline without an internet connection making it more reliable in
      busy congress environments.
    </p>
    <p>
      This system enables live feedback with its real time assessment, it can
      also be anonymous in its responses and offer a full report of statistics.
      Data can also be exported into an Excel spreadsheet where it can be
      analysed to produce charts to visualise data trends in greater detail.
    </p>
    <p>The following information can be collated during the activity:</p>
    <ul>
      <li>Number of Delegates</li>
      <li>Nationality of Delegates</li>
      <li>Delegate's Occupation</li>
      <li>Determine how many and which answers are answered correctly</li>
      <li>Determine how many and which answers are answered incorrectly</li>
      <li>
        Any gaps in their knowledge or understanding of products and procedures
        based upon the questions answered.
      </li>
      <li>The time the delegates took to answer</li>
      <li>Live feedback</li>
    </ul>
    <h3>The Process</h3>
    <ol>
      <li>
        Delegates are engaged and welcomed onto the booth by our Moderator
      </li>
      <li>Delegates take a seat and are handed an iPad</li>
      <li>
        Delegates have the option to choose a refreshment from the menu on the
        iPad this then remotely relayed to the booth barista for preparation.
      </li>
      <li>
        Moderator explains how the activity will work. Moderator will present
        from the main screen a series of questions. Answer options or comment
        boxes will appear on the iPads and Delegates can choose against the
        clock or post their short answers. This can be done as a group or played
        individually.
      </li>
      <li>Delegates are handed their refreshments.</li>
      <li>The activity starts with delegates viewing the main screen.</li>
      <li>
        Moderator presents a question on the main screen before or after any
        desired media such as case studies, graphs, images and videos are
        displayed.
      </li>
      <li>
        The answer options will appear on the main screen and also we are able
        to present question, options and associated references to delegates
        using the full iPad display
      </li>
      <li>Countdown clock begins.</li>
      <li>
        Moderator will then reveal highlighting the correct answer after the
        countdown or thank delegates for their comments if an anonymous activity.
      </li>
      <li>
        Moderator can present on the main screen an additional explanation slide
        after each question.
      </li>
      <li>
        A pie chart shows the percentage of right and wrong answers received so
        at a glance it is possible to know how the overall group responded.
      </li>
      <li>
        Each iPad will display each individual score in percentage to the
        questions answered correctly.
      </li>
      <li>
        After the set of questions the iPads could also be a means of
        downloading or emailing relevant information links afterwards to
        delegates once they have participated.
      </li>
      <li>
        During and after this activity company representatives are encouraged to
        engage with delegates utilising the whole booth space.
      </li>
    </ol>
    </div>
  </WhatWeDo>
</template>
<script>
import WhatWeDo from '@/components/WhatWeDo.vue'
export default {
  components: { WhatWeDo },
  data() {
    return {
      backgroundImage: require('@/assets/images/what-we-do/ipad-voting-system.jpg'),
    }
  },
}
</script>